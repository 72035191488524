.grid-container {
    margin: auto;
    padding: 20px;
    max-width: 1200px;
}

.grid-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

.grid-table th,
.grid-table td {
    padding: 10px;
    border: 1px solid #ccc;
}

.grid-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
}

.expand-cell {
    position: relative;
}

.expand-button {
    background-color: #ddd;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.sub-row {
    background-color: #f5f5f5;
    padding: 10px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul li {
    margin-bottom: 5px;
}

.ratings-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.ratings-table th,
.ratings-table td {
    padding: 8px;
    border: 1px solid #ccc;
}

.ratings-table th {
    background-color: #f2f2f2;
}

.ratings-table td {
    text-align: center;
}

.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px;
    border-radius: 4px;
    max-width: 300px;
    white-space: normal; /* Display full text without ellipsis */
    overflow: hidden;
}

.tooltip-indicator {
    margin-right: 4px; /* Adjust the spacing as needed */
    font-size: 14px;
    vertical-align: middle;
}